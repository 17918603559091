import { GetServerSideProps, InferGetServerSidePropsType } from "next";

import { CovidPage } from "@/components/covid/CovidPage";
import { BlockPageProps } from "@/types/Block";
import { Experience, getExperience } from "@/utils/experience";
import { getIsProduction } from "@/utils/production";

export const getServerSideProps: GetServerSideProps<{
  content: BlockPageProps | null;
  experience: Experience | null;
  isProduction: boolean;
}> = async ({ req }) => {
  const experience = getExperience(req);
  const isProduction = getIsProduction();
  let content: { default: BlockPageProps } | undefined;

  if (experience === null) {
    return {
      props: {
        content: null,
        experience,
        isProduction
      }
    };
  }

  try {
    content = await import(`../../profiles${experience?.profile}`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Invalid profile path:", e);
    return {
      notFound: true
    };
  }

  if (!content || (isProduction && !experience?.isProduction)) {
    return {
      props: {
        content: null,
        experience,
        isProduction
      }
    };
  }

  return {
    props: {
      content: content.default,
      experience,
      isProduction
    }
  };
};

const Covid = ({ content, experience }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return <CovidPage experience={experience} content={content} />;
};

export default Covid;
